<template>
  <div>
    <v-row>
      <v-col cols="12" :sm="value.fromAdmin ? 6 : 3">
        <v-autocomplete
          :items="value.statuses"
          label="Status"
          outlined
          dense
          clearable
          v-model="value.event.status"
          :disabled="value.stautsDisabled"
          placeholder="e.g active"
          @change="value.change()"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" sm="6" v-if="value.fromAdmin">
        <v-autocomplete
          :items="value.organisers"
          label="Organiser"
          outlined
          dense
          clearable
          item-text="name"
          item-value="_id"
          :filter="customFilter"
          v-model="value.event.organiser"
          :rules="[v => !!v || 'Organiser is required']"
          :disabled="value.disabled"
          placeholder="e.g active"
          @change="value.change(),onPromoterChange()"
        >
          <template v-slot:selection="data">
            <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
          </template>
          <template v-slot:item="data">
            <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" :sm="value.fromAdmin ? 4 : 3">
        <v-autocomplete
          :items="value.eventTypes"
          outlined
          dense
          :rules="[v => !!v || 'Type is required']"
          v-model="value.event.type"
          :disabled="value.disabled"
          label="Type"
          placeholder="e.g Conference"
          item-text="name"
          item-value="_id"
          @change="value.change()"
        ></v-autocomplete
      ></v-col>

      <v-col cols="12" :sm="value.fromAdmin ? 4 : 3">
        <v-autocomplete
          v-model="value.event.genre"
          :disabled="value.disabled"
          :items="value.genres"
          :rules="[v => !!v || 'Category is required']"
          label="Category"
          placeholder="e.g Food & Drink"
          outlined
          @click:clear="clear('genre')"
          dense
          clearable
          item-text="name"
          item-value="_id"
          @change="onChangeGenre()"
        ></v-autocomplete
      ></v-col>

      <v-col cols="12" :sm="value.fromAdmin ? 4 : 3">
        <v-autocomplete
          v-model="value.event.subCategory"
          :disabled="value.disabled"
          :items="value.subCategories"
          label="Subcategory"
          placeholder="e.g Non profit"
          outlined
          dense
          clearable
          item-text="name"
          item-value="_id"
          :rules="[v => !!v || !value.subCategories.length || 'Subcategory is required']"
          @change="value.change()"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0 d-flex" sm="4">
        Event is part of a series
        <v-switch class="ml-4" v-model="value.hasEventSeries" :disabled="value.disabled"></v-switch>
      </v-col>
      <v-col v-if="value.fromAdmin" sm="3" class="py-0 d-flex">
        Featured event
        <v-switch
          class="ml-4"
          v-model="value.isFeaturedEvent"
          :disabled="value.disabled"
          @change="value.change()"
        ></v-switch>
      </v-col>
    </v-row>

    <v-row v-if="value.hasEventSeries">
      <v-col cols="9" sm="10">
        <v-autocomplete
          v-model="value.event.series"
          :disabled="value.disabled"
          placeholder="Below the title of the event page"
          label="Event Series"
          outlined
          dense
          clearable
          :items="value.eventSeries"
          item-text="name"
          item-value="_id"
        ></v-autocomplete>
      </v-col>

      <v-col cols="3" sm="2">
        <v-btn :disabled="value.disabled" color="primary" block @click="dialog.addEventSeries = true">
          <v-icon class="hidden-md-and-up">mdi-plus</v-icon>
          <span class="hidden-sm-and-down"> Add new</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="3">
        <v-text-field
          placeholder="Header of the event page"
          outlined
          dense
          clearable
          label="Title"
          v-model.trim="value.event.title"
          :disabled="value.disabled"
          :rules="[
            v => !!v || 'Title is required',
            v => v && !!v.trim() || 'Title is required'
          ]"
          @change="onChangeTitle()"
        ></v-text-field
      ></v-col>

      <v-col cols="12" sm="3">
        <v-text-field
          :key="key"
          placeholder="Header of the event page"
          outlined
          label="Slug"
          dense
          clearable
          v-model="value.event.slug"
          :disabled="true"
          :rules="[v => !!v || 'Slug is required']"
          @change="value.change()"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="3">
        <v-text-field
          label="Subtitle (optional)"
          placeholder="Below the title of the event page"
          outlined
          dense
          clearable
          v-model="value.event.subtitle"
          :disabled="value.disabled"
          @change="value.change()"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="3">
        <v-autocomplete
          :items="value.performers"
          label="Performers"
          placeholder="Performers playing in this event"
          v-model="value.event.performers"
          :disabled="value.disabled"
          :item-text="'name'"
          :item-value="'_id'"
          multiple
          outlined
          dense
          clearable
          @change="value.change()"
        >
          <!-- <template v-slot:selection="{ item, index }">
            <v-chip dense v-if="index < 5">
              <span>{{ item.name }}</span>
            </v-chip>
            <span v-if="index >= 5" class="grey--text caption"> View More </span>
          </template> -->
          <template v-slot:selection="{ index }">
            <span
              v-if="index == value.event.performers.length - 1"
              class="grey--text text-caption"
              style="margin-left: 5px;"
            >
                {{ value.event.performers.length }} selected
            </span>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9" sm="4">
        <v-autocomplete
          v-model="value.event.venue"
          :disabled="value.disabled"
          :items="value.venues"
          label="Venue"
          item-text="name"
          item-value="_id"
          placeholder="Header of the event page"
          outlined
          dense
          clearable
          :rules="[v => !!v || 'Venue is required']"
          @change="value.change()"
        ></v-autocomplete>
      </v-col>

      <v-col cols="3" sm="2">
        <v-btn :disabled="value.disabled" color="primary" block @click="dialog.addVenue = true">
          <v-icon class="hidden-md-and-up">mdi-plus</v-icon>
          <span class="hidden-sm-and-down"> Add new</span>
        </v-btn>
      </v-col>

      <v-col cols="12" sm="6">
        <v-menu
          v-model="value.menu.doors"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="value.time.doors"
              :disabled="value.disabled"
              label="Doors (optional)"
              placeholder="00:00"
              readonly
              outlined
              dense
              clearable
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-time-picker
            v-model="value.time.doors"
            format="24hr"
            full-width
            @click:minute="value.menu.doors = false"
            @change="value.change()"
          ></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="3">
        <v-menu
          v-model="value.menu.eventStart"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div>
              <v-text-field
                outlined
                dense
                clearable
                label="Start date"
                placeholder="DD/MM/YY"
                v-model="value.date.eventStartDate"
                :disabled="value.disabled"
                :rules="[v => !!v || 'Start date is required']"
                readonly
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                @change="value.change()"
              />
            </div>
          </template>
          <v-date-picker
            :min="today"
            v-model="value.date.eventStartDate"
            @input="value.menu.eventStart = false"
            @change="onChangeStartDate()"
          />
        </v-menu>
      </v-col>

      <v-col cols="12" sm="3">
        <v-menu
          v-model="value.menu.eventStartTime"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="value.time.eventStartTime"
              :disabled="value.disabled"
              label="Start time"
              :rules="[v => !!v || 'Start time is required']"
              placeholder="00:00"
              readonly
              outlined
              dense
              clearable
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            format="24hr"
            v-model="value.time.eventStartTime"
            full-width
            @click:minute="value.menu.eventStartTime = false"
            @change="value.change()"
          ></v-time-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="3">
        <v-menu
          v-model="value.menu.eventEnd"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              placeholder="DD/MM/YY"
              outlined
              dense
              clearable
              readonly
              append-icon="mdi-calendar"
              label="End date"
              v-model="value.date.eventEndDate"
              :disabled="value.disabled"
              :rules="[
                v => !!v || 'End date is required',
                v => eventEndDate() || 'End date must be greater than event start date',
              ]"
              v-bind="attrs"
              v-on="on"
              @change="value.change()"
            ></v-text-field>
          </template>
          <v-date-picker
            :min="today"
            v-model="value.date.eventEndDate"
            @input="value.menu.eventEnd = false"
            @change="value.change()"
          >
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="3">
        <v-menu
          v-model="value.menu.eventEndTime"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="value.time.eventEndTime"
              :disabled="value.disabled"
              label="End time"
              :rules="[
                v => !!v || 'End time is required',
                v => eventEndTime() || 'End time must be greater than event start time',
              ]"
              placeholder="00:00"
              readonly
              outlined
              dense
              clearable
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="value.time.eventEndTime"
            format="24hr"
            full-width
            @click:minute="value.menu.eventEndTime = false"
            @change="value.change()"
          ></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6">
        <label> Event Information</label>
        <quill-editor
          v-model="value.event.blurbMain"
          :disabled="value.disabled"
          @change="onChangeQuillEditor('blurbMain')"
        >
        </quill-editor>

        <div v-if="value.validate && !value.event.blurbMain" class="v-text-field__details mt-1 px-3">
          <div class="v-messages theme--light error--text" role="alert">
            <div class="v-messages__wrapper">
              <div class="v-messages__message">Event Information time is required</div>
            </div>
          </div>
        </div>
      </v-col>

      <v-col cols="12" sm="6">
        <label>Important Event Information (optional)</label>
        <quill-editor
          v-model="value.event.blurbExtra"
          :disabled="value.disabled"
          @change="onChangeQuillEditor('blurbExtra')"
        >
        </quill-editor>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <label>
          Improve discoverability of your event by adding tags relevant to the subject matter
        </label>

        <v-combobox
          class="mt-2"
          small-chips
          dense
          clearable
          outlined
          v-model="value.event.tags"
          :disabled="value.disabled"
          deletable-chips
          label="Tags"
          multiple
          chips
        ></v-combobox>
      </v-col>
    </v-row>

    <UploadImage :value="value" />

    <AddVenue v-if="dialog.addVenue" @close="onCloseVenue" />
    <AddEventSeries v-if="dialog.addEventSeries" @close="onCloseEventSeries" />
  </div>
</template>

<script>
import moment from 'moment';
import AddVenue from './dialogs/add-venue';
import AddEventSeries from './dialogs/add-event-series';
import UploadImage from './upload-image';

export default {
  components: {
    AddVenue,
    UploadImage,
    AddEventSeries,
  },

  props: {
    value: { type: Object, required: true },
  },

  data() {
    return {
      dialog: {
        addVenue: false,
        addEventSeries: false,
      },
      key: 0,
      today: new Date().toISOString().substr(0, 10),
    };
  },

  methods: {
    customFilter (item, queryText) {
      const textOne = `${item.name.toLowerCase()} - ${item.type.toLowerCase()}`
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 
    },
    onChangeTitle() {
      this.value.change();
      if (!this.value.event.slug) {
        this.value.event.slug = this.randomString(10, '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
        this.key++;
      }
    },

    clear() {
      this.value.subCategories = [];
    },

    onChangeStartDate() {
      this.value.change();

      if (!this.value.date.eventEndDate) {
        this.value.date.eventEndDate = this.value.date.eventStartDate;
      }
    },

    onChangeGenre() {
      this.value.change();
      this.$emit('change-genre');
    },

    onChangeQuillEditor(key) {
      if (this.value[key] !== this.value.event[key]) {
        this.value.change();
      }
    },

    eventEndDate() {
      return moment(this.value.date.eventEndDate).isSameOrAfter(this.value.date.eventStartDate);
    },

    eventEndTime() {
      return moment(this.value.date.eventEndDate).isSame(this.value.date.eventStartDate)
        ? this.value.time.eventEndTime > this.value.time.eventStartTime
        : true;
    },

    onCloseVenue(response) {
      this.dialog.addVenue = false;

      if (response) {
        this.value.event.venue = response._id;
        this.$emit('change-venue');
      }
    },

    onCloseEventSeries(response) {
      this.dialog.addEventSeries = false;

      if (response) {
        this.value.event.series = response._id;
        this.$emit('change-event-series');
      }
    },

    onPromoterChange() {
      this.$bus.$emit('onChangePromoter')
    }
  },
};
</script>
