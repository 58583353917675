<template>
  <v-dialog v-model="dialog" width="800" @click:outside="close()">
    <v-card class="pa-4" v-if="isLoaded">
      <v-card-title class="justify-center">
        <v-icon color="primary" x-large>mdi-calendar</v-icon>
      </v-card-title>

      <v-card-text>
        <v-card-title class="my-2 justify-center">Do you want to save event as draft or publish it now?</v-card-title>
        <v-card-subtitle class="text-center"
          >Be aware that created event needs to be reviewed and approved by admin after when your event will be
          published online.</v-card-subtitle
        >
      </v-card-text>

      <v-card-actions>
        <div class="mx-auto">
          <v-btn class="mx-1" width="200" @click="close('draft')">Save as draft</v-btn>

          <v-btn class="mx-1" width="200" color="primary" @click="close((isStatusVetting && !value.fromAdmin) ? 'vetting' : 'active')">
            {{ (isStatusVetting && !value.fromAdmin) ? 'Request to publish' : 'Publish' }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { UserService } from '@services';
export default {
  props: {
    value: { type: Object, required: true },
  },
  data() {
    return {
      dialog: true,
      title: null,
      subtitle: null,
      resolve: null,
      isStatusVetting: false,
      isLoaded: false
    };
  },
  async created() {
    if (this.$user.organiser) {
      const organiserdata = await UserService.getOrganiserInfo(this.$user.organiser);
      this.isStatusVetting = (organiserdata) ? organiserdata.isStatusVetting :  this.value.isStatusVetting;
    }
    this.isLoaded = true;
  },
  methods: {
    close(status) {
      this.$emit('close', status);
    },
  },
};
</script>
