<template>
  <v-dialog v-model="dialog" width="800px" @click:outside="cancel()">
    <v-card>
      <v-card-title class="primary--text">
        Create Event Series
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                outlined
                dense
                name="name"
                clearable
                label="Name"
                v-model="data.name"
                :rules="[v => !!v || 'Name is required']"
                id="name"
                @input="nameError = []"
                :error-messages="nameError"
                @change="onChangeName()"
                placeholder="e.g. Name"
              />
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                v-model="data.organiser"
                clearable
                :items="organisers"
                item-text="name"
                item-value="_id"
                label="Promoter"
                :rules="[v => !!v || 'Promoter is required']"
                dense
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" class="py-0">
              <v-autocomplete
                v-model="data.eventType"
                clearable
                :items="eventTypes"
                item-text="name"
                item-value="_id"
                label="Type"
                :rules="[v => !!v || 'Type is required']"
                dense
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-autocomplete
                v-model="data.genre"
                clearable
                :items="genres"
                item-text="name"
                item-value="_id"
                label="Category"
                @change="getSubCategory()"
                :rules="[v => !!v || 'Category is required']"
                dense
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-autocomplete
                v-model="data.subCategory"
                clearable
                :items="subcategories"
                item-text="name"
                item-value="_id"
                label="Sub Category"
                :rules="[v => !!v || 'Sub Category is required']"
                dense
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="primary--text text-bold"> Add links </v-col>
          </v-row>

          <v-row v-for="(link, index) in data.links" :key="index">
            <v-col cols="12" sm="3" class="py-0">
              <v-select
                outlined
                dense
                clearable
                :items="getLinkTypes(link)"
                v-model="link.linkType"
                label="Link type"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="8" class="py-0">
              <v-text-field outlined dense hide-details v-model="link.url"></v-text-field>
            </v-col>

            <v-col cols="12" sm="1" class="py-0">
              <v-btn color="primary" icon @click="removeLink(index)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-btn v-if="data.links && data.links.length < 3" color="primary" text @click="addLink()">
            <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
            Add link
          </v-btn>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined dense color="primary" @click="cancel()">Cancel</v-btn>
        <v-btn width="100" color="primary" :loading="loading" @click="save()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventSeries, EventTypeService, CategoryService, SubCategoryService, PromoterService } from '@services';

export default {
  data() {
    return {
      dialog: true,
      loading: false,
      errors: [],
      valid: false,
      linkTypes: [
        { text: 'Youtube', value: 'youtube' },
        { text: 'Facebook', value: 'facebook' },
        { text: 'Twitter', value: 'twitter' },
      ],
      data: {
        name: '',
        organiser: '',
        eventType: '',
        genre: '',
        subCategory: '',
        links: [{ linkType: '', url: '' }],
      },
      eventTypes: [],
      genres: [],
      subcategories: [],
      organisers: [],
      nameError: [],
    };
  },
  async created() {
    const eventData = await EventTypeService.get({ limit: 'all' });
    const genreData = await CategoryService.get({ limit: 'all' });
    const organiserData = await PromoterService.get({ limit: 'all' });
    this.eventTypes = eventData.eventTypes;
    this.genres = genreData.genres;
    this.organisers = organiserData.organisers;
    this.getSubCategory();
  },
  methods: {
    async getSubCategory() {
      const subCategoryData = await SubCategoryService.get({ genre: this.data.genre, limit: 'all' });
      this.subcategories = subCategoryData.subCategories;
    },
    async save() {
      if (this.$refs.form.validate() && !this.nameError.length) {
        this.loading = true;

        const response = await EventSeries.save(this.data);
        if (response) {
          this.dialog = false;
          this.$emit('close', response);
        }
        this.loading = false;
      }
    },
    async onChangeName() {
      this.nameError = [];
      if (this.data.name) {
        const response = await EventSeries.checkDuplicate(this.data.name);
        if (response) {
          if (response._id != this.data._id) {
            this.nameError = ['Name already exists'];
          }
        }
      }
    },
    cancel() {
      this.dialog = false;
      this.$emit('close');
    },

    getLinkTypes(link) {
      return this.linkTypes.filter(linkType => {
        return link.linkType === linkType.value || !this.data.links.some(_link => _link.linkType === linkType.value);
      });
    },

    addLink() {
      this.data.links = this.data.links || [];
      this.data.links.push({});
    },

    removeLink(index) {
      this.data.links.splice(index, 1);
    },
  },
};
</script>
