<template>
  <v-card outlined class="mt-4">
    <v-card-title class="primary--text">Upload Image<span style="font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
    margin-left: 5px;
    color: rgba(0, 0, 0, 0.6);"> (optional)</span></v-card-title>
    
    <v-card-text>
      <v-img :aspect-ratio="$vuetify.breakpoint.xs ? 16 / 9 : 10 / 4" class="v-sheet--outlined" v-if="url" :src="url">
        <v-btn class="float-right" color="error" fab icon @click="removeImage">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </v-img>

      <v-img
        :aspect-ratio="$vuetify.breakpoint.xs ? 16 / 9 : 10 / 4"
        class="v-sheet--outlined"
        v-else-if="value.event.image"
        :src="$getOrignalImage(value.event.image, value.event.imageUserId, value.event._id)"
      >
        <v-btn class="float-right" color="error" fab icon  :disabled="value.disabled" @click="removeImage">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>

        <template v-slot:placeholder>
          <v-progress-circular class="center" indeterminate color="primary"></v-progress-circular>
        </template>
      </v-img>

      <v-responsive v-else :aspect-ratio="$vuetify.breakpoint.xs ? 16 / 9 : 4">
        <div 
          class="border-dashed h-100 cursor-pointer"
          :class="{'v-input--is-disabled': value.disabled}"
          @click="$refs.file.click()"
          @drop.prevent="drop"
          @dragover.prevent
        >
          <div class="center">
            <v-icon x-large>mdi-file-outline</v-icon>
            Drag n Drop here or <span style="color: red;"> Browse</span> to add main event image.<br/>
            <span style="margin-left: 110px;">JPG, JPEG or PNG, no larger than 12MB.</span>
          </div>
        </div>
      </v-responsive>
    </v-card-text>
    <v-card-subtitle class="primary--text">Please upload image with minimum dimensions 100x100.</v-card-subtitle>
    <v-card-subtitle class="primary--text" style="padding: 0px 16px">For better quality upload image with dimensions 1600x900.</v-card-subtitle>

    <v-card-actions>
      <v-spacer />
      <v-btn class="px-16" large color="secondary" :disabled="value.disabled" @click="$refs.file.click()">
        {{ url || value.event.image ? 'Replace' : 'Upload' }} image
      </v-btn>
      <v-spacer />
    </v-card-actions>

    <input
      type="file"
      ref="file"
      class="d-none"
      multiple
      accept="image/png, image/jpeg"
      onclick="this.value = null;"
      @change="changeFile()"
    />
  </v-card>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    value: { type: Object, required: true },
  },

  data() {
    return {
      url: null,
    };
  },
  mounted() {
    this.$root.$on('image-error', id => {
      this.url = null;
      this.value.event.image = null;
      this.value.changed = false;
      this.value.file = null;
    });
  },
  methods: {
    changeFile() {
      if (this.$refs.file.files[0].size > Number(process.env.VUE_APP_IMAGE_SIZE)) {
        this.$error(
          'Max file size is 12 mb'
        );
        return;
      } else {
        let img = new Image();
        img.src = window.URL.createObjectURL(this.$refs.file.files[0]);
        img.onload = () => {
          if (img.width >= Number(process.env.VUE_APP_IMAGE_WIDTH) && img.height >= Number(process.env.VUE_APP_IMAGE_HEIGHT)) {
            this.addFiles(this.$refs.file.files);
          } else {
            this.$error(
              'Please use an image 100 x 100 or above.'
            );
          }
        };
      }
      
    },

    drop(event) {
      if (event.target.files[0].size > Number(process.env.VUE_APP_IMAGE_SIZE)) {
        this.$error(
          'Max file size is 12 mb'
        );
        return;
      } else {
        let img = new Image();
        img.src = window.URL.createObjectURL(event.target.files[0]);
        img.onload = () => {
          if (img.width >= Number(process.env.VUE_APP_IMAGE_WIDTH) && img.height >= Number(process.env.VUE_APP_IMAGE_HEIGHT)) {
            this.addFiles(event.target.files);
          } else {
            this.$error(
              'Please use an image 100 x 100 or above.'
            );
          }
        };
      }
      this.addFiles(event.dataTransfer.files);
    },

    removeImage() {
      this.$confirm({
        title: `Are you sure you want to delete this Image?`,
        resolve: async () => {
          this.url = null;
          this.value.oldImage = _.cloneDeep(this.value.event.image);
          this.value.event.image = null;
          this.value.changed = true;
          this.value.file = null;
        },
      });
      
    },

    addFiles(files) {
      if (files) {
        [...files].forEach(file => {
          this.url = URL.createObjectURL(file);
          this.value.file = file;
          this.value.changed = true;
        });
      }
    },
  },
};
</script>

<style scoped>
.border-dashed {
  border: 2px dashed;
}
</style>
