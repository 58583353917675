<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12">
        <v-text-field
          type="number"
          v-model="value.event.purchaseLimit"
          v-on:keypress="isNumber($event)"
          outlined
          dense
          clearable
          min="1"
          label="Max per Order"
          :rules="[
            v => !!v || 'Max value is required',
          ]"
          @change="value.change();changePurchaseLimit()"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="3">
        <v-menu
          v-model="value.menu.ticketFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div>
              <v-text-field
                v-bind="attrs"
                v-on="on"
                readonly
                append-icon="mdi-calendar"
                v-model="value.date.availableFrom"
                label="Ticket available from (optional)"
                outlined
                dense
                clearable
                @change="value.change()"
                :rules="[v => eventEndDate() || 'Ticket available from must be less than Ticket available until']"
              ></v-text-field>
            </div>
          </template>
          <v-date-picker
            :min="today"
            v-model="value.date.availableFrom"
            @input="value.menu.ticketFrom = false"
            @change="value.change()"
          />
        </v-menu>
      </v-col>
      <v-col cols="12" sm="3">
        <v-menu
          v-model="value.menu.availableStartTime"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="value.time.availableStartTime"
              :disabled="value.disabled"
              label="Ticket available from Start time (optional)"
              placeholder="00:00"
              readonly
              outlined
              :rules="[v => startTime() || 'Start time must be less than end time']"
              dense
              clearable
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="value.time.availableStartTime"
            format="24hr"
            full-width
            @click:minute="value.menu.availableStartTime = false"
            @change="value.change()"
          ></v-time-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="3">
        <v-menu
          v-model="value.menu.ticketUnitll"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div>
              <v-text-field
                v-bind="attrs"
                v-on="on"
                readonly
                append-icon="mdi-calendar"
                v-model="value.date.availableUntil"
                label="Ticket available until (optional)"
                outlined
                dense
                clearable
                @change="value.change()"
                :rules="[
                  v => eventEndDate() || 'Ticket available until must be greater than Ticket available from',
                  v => availableEndDate() || 'Ticket available until must be less than Event end Date'
                ]"
              ></v-text-field>
            </div>
          </template>
          <v-date-picker
            :min="today"
            v-model="value.date.availableUntil"
            @input="value.menu.ticketUnitll = false"
            @change="value.change()"
          />
        </v-menu>
      </v-col>
      <v-col cols="12" sm="3">
        <v-menu
          v-model="value.menu.availableEndTime"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="value.time.availableEndTime"
              :disabled="value.disabled"
              label="Ticket available from End time (optional)"
              placeholder="00:00"
              readonly
              outlined
              :rules="[
                v => eventEndTime() || 'End time must be greater than start time',
                v => availableEndTime() || 'Ticket available until must be less than Event end Date'
              ]"
              dense
              clearable
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="value.time.availableEndTime"
            format="24hr"
            full-width
            @click:minute="value.menu.availableEndTime = false"
            @change="value.change()"
          ></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-divider />

    <v-card flat>
      <v-card-title class="px-0">Advance details</v-card-title>

      <v-card-text class="px-0">
        <v-autocomplete
          :items="value.seatingPlans"
          item-text="name"
          item-value="_id"
          placeholder="Test_1"
          outlined
          :disabled="value.event.seatsIOEventId"
          dense
          clearable
          label="Seating Plan (optional)"
          v-model="value.event.seatingPlan"
          @change="onChangeSeatingPlan()"
        ></v-autocomplete>
        <template v-if="loaded">
          <v-card class="mb-4" outlined v-for="(ticket, index) in value.tickets" :key="index">
            <v-card-title>
              #{{ index + 1 }} 
              <v-spacer></v-spacer>
              <v-btn icon color="red" v-show="index !== 0" @click="moveTicket(ticket, index, 'up')">
                <v-icon>mdi-arrow-up-circle</v-icon>
              </v-btn>
              <v-btn icon color="red" v-show="(value.tickets.length - 1) !== index" @click="moveTicket(ticket, index, 'down')">
                <v-icon>mdi-arrow-down-circle</v-icon>
              </v-btn>
              <v-spacer />
              <v-btn :disabled="value.disabled" :loading="ticket.loading" icon @click="removeTicket(ticket, index)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-row>
                <!-- <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="ticket.type"
                    :items="value.types"
                    outlined
                    dense
                    clearable
                    label="Type"
                    disabled
                    :rules="[v => !!v || 'Ticket Type is required']"
                    @change="value.change(ticket)"
                  ></v-autocomplete>
                </v-col> -->
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="ticket.category"
                    :items="getTicketCategories(index)"
                    outlined
                    dense
                    clearable
                    item-text="name"
                    item-value="_id"
                    label="Ticket Category"
                    :rules="[v => !!v || 'Ticket Category is required']"
                    @change="value.change(ticket)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    type="number"
                    outlined
                    dense
                    clearable
                    min="1"
                    v-model="ticket.purchaseLimit"
                    label="Max per Order"
                    v-on:keypress="isNumber($event)"
                    @change="value.change(ticket)"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" :sm="ticket.type != 'Reserved Seating' ? 3 : 4">
                  <v-text-field
                    type="number"
                    @input="changeFee(ticket, true)"
                    outlined
                    dense
                    clearable
                    :disabled="!value.fromAdmin && ticket._id && !isDuplicate"
                    v-model="ticket.price"
                    label="Face value"
                    :rules="[
                      v => facevalueRequired(ticket.price) || 'Face value is required',
                      v => desimalNumberFormat(ticket.price) || 'Only two digits after decimal points allowed.',
                    ]"
                    @change="onChangeFaceValue(ticket)"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" :sm="ticket.type != 'Reserved Seating' ? 3 : 4">
                  <v-text-field
                    type="number"
                    v-model="ticket.bookingFeePercentage"
                    @input="changeFee(ticket)"
                    :disabled="!value.fromAdmin"
                    append-icon="mdi-percent-outline"
                    v-on:keypress="isNumber($event)"
                    outlined
                    dense
                    clearable
                    label="Booking Fee (in %)"
                    :rules="[
                      v => (!!v || v === 0) || 'Booking Fee (in %) is required',
                      v => desimalNumberFormat(ticket.bookingFeePercentage) || 'Only two digits after decimal points allowed.',
                    ]"
                    @change="value.change(ticket)"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" :sm="ticket.type != 'Reserved Seating' ? 3 : 4">
                  <v-text-field
                    type="number"
                    v-model="ticket.bookingFee"
                    @input="changeFeePercentage(ticket)"
                    outlined
                    dense
                    clearable
                    :disabled="!value.fromAdmin"
                    :rules="[
                      v => (!!v || v === 0) || 'Booking Fee is required',
                      v => desimalNumberFormat(ticket.bookingFee) || 'Only two digits after decimal points allowed.',
                    ]"
                    label="Booking Fee"
                    @change="value.change(ticket)"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="3" v-if="ticket.type != 'Reserved Seating'">
                  <v-text-field
                    v-model="ticket.availableCount"
                    v-on:keypress="isNumber($event)"
                    outlined
                    dense
                    min="1"
                    clearable
                    label="Quantity"
                    :rules="[
                      v => !!v || 'Quantity is required',
                    ]"
                    @change="value.change(ticket)"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-menu
                    v-model="ticket.ticketFrom"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div>
                        <v-text-field
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          append-icon="mdi-calendar"
                          v-model="ticket.availableFrom"
                          label="From Date"
                          outlined
                          dense
                          clearable
                          @change="value.change(ticket)"
                          :rules="[
                            v => ticketEndDate(ticket) || 'Ticket available from must be less than Ticket available until',
                          ]"
                        ></v-text-field>
                      </div>
                    </template>
                    <v-date-picker
                      :min="today"
                      v-model="ticket.availableFrom"
                      @input="ticket.ticketFrom = false"
                      @change="value.change(ticket)"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-menu
                    v-model="ticket.ticketavailableStartTime"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="ticket.availableStartTime"
                        :disabled="value.disabled"
                        label="From Time"
                        placeholder="00:00"
                        readonly
                        outlined
                        :rules="[v => startTime() || 'Start time must be less than end time']"
                        dense
                        clearable
                        v-bind="attrs"
                        @change="value.change(ticket)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="ticket.availableStartTime"
                      format="24hr"
                      full-width
                      @click:minute="ticket.ticketavailableStartTime = false"
                      @change="value.change(ticket)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="3">
                  <v-menu
                    v-model="ticket.ticketUnitll"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div>
                        <v-text-field
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          append-icon="mdi-calendar"
                          v-model="ticket.availableUntil"
                          label="Until Date"
                          outlined
                          dense
                          clearable
                          @change="value.change(ticket)"
                          :rules="[
                            v => ticketEndDate(ticket) || 'Ticket available until must be greater than Ticket available from',
                            v => ticketAvailableEndDate(ticket) || 'Ticket available until must be less than Event end Date'
                            
                          ]"
                        ></v-text-field>
                      </div>
                    </template>
                    <v-date-picker
                      :min="today"
                      v-model="ticket.availableUntil"
                      @input="ticket.ticketUnitll = false"
                      @change="value.change(ticket)"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-menu
                    v-model="ticket.ticketavailableEndTime"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="ticket.availableEndTime"
                        :disabled="value.disabled"
                        label="Until Time"
                        placeholder="00:00"
                        readonly
                        outlined
                        :rules="[
                          v => ticketEndTime(ticket) || 'End time must be greater than start time',
                          v => ticketAvailableEndTime(ticket) || 'Ticket available until must be less than Event end Date'
                        ]"
                        dense
                        clearable
                        @change="value.change(ticket)"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="ticket.availableEndTime"
                      format="24hr"
                      full-width
                      @click:minute="ticket.ticketavailableEndTime = false"
                      @change="value.change(ticket)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="5">
              <v-switch
                v-model="ticket.disableSeatSelection"
                label="Prevent customers from selecting their own seats?"
                @change="value.change(ticket)"
              ></v-switch>
              </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>

        <v-row class="mt-4">
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3">
            <v-btn color="primary" block @click="addTicket()" :disabled="value.disabled">Add Ticket</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import { PromoterEventService } from '@services';

export default {
  props: {
    value: { type: Object, required: true },
  },

  data() {
    return {
      today: new Date().toISOString().substr(0, 10),
      ticketCategories: [],
      loaded: false,
      isDuplicate: false,
    };
  },

  mounted() {
    if (this.$route.query.isDuplicate) {
      this.isDuplicate = true;
    }
    if (!this.value.tickets.length) {
      this.addTicket();
    } else {
      for (let index = 0; index < this.value.tickets.length; index++) {
        const element = this.value.tickets[index];
        if (!element.bookingFeePercentage) {
          this.value.tickets[index].bookingFeePercentage = 10;
        }
        if (!element.purchaseLimit) {
          this.value.tickets[index].purchaseLimit = this.value.event.purchaseLimit
        }
      }
    }
    this.loaded = true;
  },

  methods: {
    onChangeFaceValue(ticket) {
      this.value.change(ticket);

      if (!ticket.bookingFee) {
        ticket.bookingFee = parseFloat(ticket.price) * 0.1;
      }
    },

    onChangeSeatingPlan() {
      this.$confirm({
        title: `Are you sure you want to change seating plan?`,
        skip: !this.value.tickets.length,
        disabled: !this.value.setEvent,
        resolve: () => {
          this.value.tickets.forEach(ticket => {
            ticket.category = null;
            this.setSeatingPlan(ticket);
            this.value.change(ticket);
          });
        },
      });
    },

    setSeatingPlan(ticket) {
      if (this.value.event.seatingPlan) {
        ticket.type = 'Reserved Seating';
      } else {
        ticket.type = 'Unreserved Seating';
      }
    },

    changeFee(ticket, onlyfaceValueChange) {
      if (onlyfaceValueChange && ticket.price && (ticket.bookingFeePercentage == 0)) {
        ticket.bookingFeePercentage = 10;
      }

      if (ticket.price && (ticket.bookingFeePercentage || ticket.bookingFeePercentage == 0)) {
        ticket.bookingFee = ((ticket.price * ticket.bookingFeePercentage) / 100).toFixed(2);
      }
      
      if(ticket.bookingFee < 1 && onlyfaceValueChange) {
        ticket.bookingFee = 1;
      }
      if(ticket.price && ticket.price !== '' && +ticket.price === 0) {
        ticket.bookingFee = 0;
      }
      if(ticket.bookingFeePercentage == 0) {
        ticket.bookingFee = 0;
      }
    },
    changeFeePercentage(ticket) {
      if (ticket.price && (ticket.bookingFee || ticket.bookingFee == 0)) {
        ticket.bookingFeePercentage = ((100 * ticket.bookingFee) / ticket.price).toFixed(2);
      }
    },
    eventEndTime() {
      return moment(this.value.date.availableFrom).isSame(this.value.date.availableUntil) &&
        this.value.time.availableEndTime &&
        this.value.time.availableStartTime
        ? this.value.time.availableEndTime > this.value.time.availableStartTime
        : true;
    },
    ticketEndTime(ticket) {
      return moment(ticket.availableFrom).isSame(ticket.availableUntil) &&
        ticket.availableEndTime &&
        ticket.availableStartTime
        ? ticket.availableEndTime > ticket.availableStartTime
        : true;
    },
    ticketAvailableEndTime(ticket) {
      return moment(this.value.date.eventEndDate).isSame(ticket.availableUntil) &&
        ticket.availableEndTime &&
        this.value.time.availableEndTime
        ? ticket.availableEndTime > this.value.time.availableEndTime
        : true;
    },
    startTime() {
      return moment(this.value.date.availableFrom).isSame(this.value.date.availableUntil) &&
        this.value.time.availableEndTime &&
        this.value.time.availableStartTime
        ? this.value.time.availableEndTime > this.value.time.availableStartTime
        : true;
    },

    availableEndTime() {
      return moment(this.value.date.eventEndDate).isSame(this.value.date.availableUntil) &&
        this.value.time.availableEndTime &&
        this.value.time.eventEndTime
        ? this.value.time.eventEndTime > this.value.time.availableEndTime
        : true;
    },

    getTicketCategories(index) {
      let ticketCategories = this.value.ticketCategories;

      if (this.value.event.seatingPlan) {
        const seatingPlan = this.value.seatingPlans.find(plan => plan._id === this.value.event.seatingPlan);

        if (seatingPlan) {
          ticketCategories = seatingPlan.seats.reduce((array, seat) => {
            const ticketCategory = this.value.ticketCategories.find(category => category._id === seat.category);
            if (ticketCategory) {
              array.push(ticketCategory);
            }

            return array;
          }, []);
        }
      }

      return ticketCategories.filter(category => {
        return !this.value.tickets.some((ticket, i) => {
          return index != i && category._id == ticket.category;
        });
      });
    },

    async removeTicket(ticket, index) {
      if (ticket._id) {
        this.$confirm({
          title: `Are you sure you want to delete this Ticket Category?`,
          resolve: async () => {
            ticket.loading = true;
            const response = await PromoterEventService.deleteTicketCategory(this.value.event._id, ticket._id);
            if (response || response == '') {
              this.value.tickets.splice(index, 1);
            }
            ticket.loading = false;
          },
        });
      } else {
        for(let i = index; i< this.value.tickets.length; i++) {
          this.value.tickets[i].sortOrder = (this.value.tickets[i].sortOrder -1);
        }
        this.value.tickets.splice(index, 1);
      }
    },

    eventEndDate() {
      return this.value.date.availableFrom && this.value.date.availableUntil
        ? moment(this.value.date.availableUntil).isSameOrAfter(this.value.date.availableFrom)
        : true;
    },

    availableEndDate() {
      return this.value.date.eventEndDate && this.value.date.availableUntil
        ? moment(this.value.date.eventEndDate).isSameOrAfter(moment(this.value.date.availableUntil))
        : true;
    },

    ticketEndDate(ticket) {
      return ticket.availableFrom && ticket.availableUntil
        ? moment(ticket.availableUntil).isSameOrAfter(ticket.availableFrom)
        : true;
    },
    ticketAvailableEndDate(ticket) {
      return this.value.date.eventEndDate && ticket.availableUntil
        ? moment(this.value.date.eventEndDate).isSameOrAfter(moment(ticket.availableUntil))
        : true;
    },
    addTicket() {
      const ticket = {
        bookingFeePercentage: 0,
        bookingFee: 0,
        price: 0,
        sortOrder: (this.value.tickets.length + 1)
      };
      this.setSeatingPlan(ticket);
      this.value.tickets.push(ticket);
    },

    bookingFeeRule(ticket) {
      return (ticket.price && !this.value.fromAdmin) && ticket.bookingFeePercentage ? ticket.bookingFeePercentage >= 10 : true;
    },
    facevalueRequired(value) {
      return value || value === 0 ? true : false;
    },
    desimalNumberFormat(value) {
      value = value.toString();
      value = value.split('.');
      return value.length === 2 && value[1].length > 2 ? false : true;
    },
    changePurchaseLimit() {
      for (let index = 0; index < this.value.tickets.length; index++) {
        const element = this.value.tickets[index];
        if (!element.purchaseLimit) {
          this.value.tickets[index].purchaseLimit = this.value.event.purchaseLimit
        }
      }
    },
    moveTicket(ticket, index, direction) {
      let fromIndex, toIndex;
      let tickets = this.value.tickets;
      if(direction === 'up') {
        toIndex = index;
        fromIndex = index - 1;
      } else if(direction === 'down') {
        toIndex = index;
        fromIndex = index + 1;
      }
      let currentTicket  = tickets[fromIndex];
      this.value.tickets.splice(fromIndex, 1);
      this.value.tickets.splice(toIndex, 0, currentTicket);
      this.value.tickets[index].changed = true;
      this.value.tickets[index].sortOrder = (index + 1);
      this.value.tickets[fromIndex].sortOrder = (fromIndex + 1);
      this.value.tickets[fromIndex].changed = true;
      this.value.change(this.value.tickets[index]);
      this.value.change(this.value.tickets[fromIndex]);
    }
  },
};
</script>
