<template>
  <div>
    <v-card outlined class="mb-4">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              label="Age Restriction"
              outlined
              dense
              clearable
              v-model="value.event.ageRestrictions"
              v-on:keypress="isNumber($event)"
              @change="value.change()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="9">
            <v-textarea
              v-model="value.event.infoMessage"
              label="Age Group Information"
              outlined
              dense
              clearable
              @change="value.change()"
            ></v-textarea>

            <v-textarea
              v-model="value.event.timingInformation"
              label="Timing information"
              outlined
              dense
              clearable
              @change="value.change()"
            ></v-textarea>
            <v-textarea
              v-model="value.event.additionalInformation"
              label="Event terms & conditions"
              outlined
              dense
              clearable
              @change="value.change()"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card outlined class="mb-4">
      <v-card-title class="primary--text text-bold">
        Guest lists

        <v-spacer />
        <v-btn :disabled="value.disabled" color="primary" width="150" @click="addGuestList()"> Add Guest list </v-btn>
      </v-card-title>

      <v-card-subtitle>
        Add attendees to your event without impacting ticket inventory or event capacity
      </v-card-subtitle>

      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" sm="12">
            <v-card outlined class="mb-4" v-for="(guestList, index) in value.event.guests" :key="index">
              <v-card-title>
                {{ guestList.title }}

                <!-- <v-btn color="primary" icon @click="editGuest(null, guestList)">
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn> -->

                <v-spacer />
                <v-btn :disabled="value.disabled" color="primary" icon @click="editGuest(null, guestList)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>

                <v-btn :disabled="value.disabled" color="primary" icon @click="removeGuestList(index, guestList)">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </v-card-title>
              <v-simple-table fixed-header v-if="guestList.guests && guestList.guests.length">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Email</th>
                      <th class="text-left">Number</th>
                      <th class="text-left">Company Name</th>
                      <th class="text-left">#Seats</th>
                      <th class="text-left">Additional Comments</th>
                      <th class="text-left">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in guestList.guests" :key="index">
                      <td>{{ item.name }}</td>
                      <td>{{ item.email }}</td>
                      <td>{{ item.number }}</td>
                      <td>{{ item.companyName }}</td>
                      <td>{{ item.seats }}</td>
                      <td>{{ item.comments }}</td>
                      <td>
                        <v-icon color="primary" @click="editGuest(index, guestList)"> mdi-pencil-outline</v-icon>
                        <v-icon color="primary" @click="removeGuest(index, guestList)"> mdi-delete-outline</v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-divider></v-divider>

              <v-card-text v-if="!guestList.guests || !guestList.guests.length">
                <v-card-title class="justify-center">No guests added</v-card-title>
                <v-card-subtitle class="text-center">Add guests here to give entry to your event</v-card-subtitle>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card outlined class="mb-4">
      <v-card-title class="primary--text text-bold"> Add links </v-card-title>

      <v-card-text>
        <v-row v-for="(link, index) in value.event.links" :key="index">
          <v-col cols="12" sm="3">
            <v-select
              outlined
              dense
              clearable
              :items="getLinkTypes(link)"
              v-model="link.linkType"
              label="Link type"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              type="url"
              outlined
              dense
              v-model="link.url"
              :rules="[
                v =>
                  !v ||
                  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                    v
                  ) ||
                  'Invalid URL',
              ]"
              @change="value.change()"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="1">
            <v-btn :disabled="value.disabled" color="primary" icon @click="removeLink(index)">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-btn
          :disabled="value.disabled"
          v-if="value.event.links && value.event.links.length < 3"
          color="primary"
          text
          @click="addLink(index)"
        >
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
          Add link
        </v-btn>
      </v-card-text>
    </v-card>

    <v-card outlined class="mb-4" v-if="isAdmin">
      <v-card-title class="primary--text text-bold">
        <v-col class="py-0 d-flex" sm="4">
          <v-switch
            v-model="value.event.isHaveAddson"
            :disabled="value.disabled"
            style="margin-top: 4px !important;"
          ></v-switch>
          <span>Add Ons</span>
        </v-col>
      </v-card-title>
      <v-card-text v-if="value.event.isHaveAddson" :key="key">
        <v-card outlined class="mb-4" v-for="(item, index) in value.event.addson" :key="index">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="11">
                <v-select
                  outlined
                  dense
                  :disabled="item._id ? true : false"
                  :items="getFilterAddson(index)"
                  :item-text="'name'"
                  :item-value="'_id'"
                  v-model="item.addon"
                  label="Add Ons"
                  v-on:change="getAddonById(item.addon, index)"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="1">
                <v-btn :disabled="value.disabled" color="primary" icon @click="removeAddson(index)">
                  <v-icon v-if="!item.isHideDelete">mdi-delete-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-for="(attributes, attributesIndex) in item.attributes" :key="attributesIndex">
              <v-col cols="12" sm="4">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  disabled
                  label="Value"
                  v-model="attributes.value"
                  name="value"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  disabled
                  label="Price"
                  v-model="attributes.price"
                  name="value"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  label="Quantity"
                  v-model="attributes.quantity"
                  :rules="[
                    v => !!`${v}` || 'Quantity is required',
                    v =>
                      !(attributes.purchaseQuantity > attributes.quantity) ||
                      `Min Quantity Allow ${attributes.purchaseQuantity}`,
                    quantityRequired(item),
                  ]"
                  name="quantity"
                  v-on:keypress="isNumber($event)"
                  @change="value.change()"
                  @input="checkAddonQuantity(item, attributesIndex, attributes, index)"
                  :error-messages="attributes.errors"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-btn :disabled="value.disabled" color="primary" text @click="addAddon(index)">
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
          Add Add Ons
        </v-btn>
      </v-card-text>
    </v-card>

    <v-card outlined class="mb-4" v-if="isAdmin">
      <v-card-title class="primary--text text-bold">
        <v-col class="py-0 d-flex" sm="4">
          <v-switch
            v-model="value.event.isHaveSurvey"
            :disabled="value.disabled"
            style="margin-top: 4px !important;"
            @change="surveyChange()"
          ></v-switch>
          <span>Create Survey</span>
        </v-col>
      </v-card-title>
      <v-card-text v-if="value.event.isHaveSurvey" :key="key">
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              label="Name"
              outlined
              dense
              clearable
              v-model="value.event.survey.name"
              @change="value.change()"
              :rules="[v => !!`${v}` || 'Survey name is required']"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="9">
            <v-textarea
              v-model="value.event.survey.description"
              label="Description"
              outlined
              dense
              clearable
              @change="value.change()"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row v-for="(question, questionIndex) in value.event.survey.questions" :key="questionIndex">
          <v-col cols="12" sm="8">
            <v-text-field
              type="text"
              label="Question"
              outlined
              dense
              v-model="question.question"
              @change="value.change()"
              :rules="[v => !!`${v}` || 'Question is required']"
            ></v-text-field>
            <template v-for="(answer, answerIndex) in question.options">
              <v-text-field
                type="text"
                label="Add Option"
                :prepend-icon="iconTypes[question.questionType]"
                append-outer-icon="mdi-close"
                dense
                @click:append-outer="removeOption(answerIndex, questionIndex)"
                v-model="answer.answer"
                @change="value.change()"
                :rules="[v => !!`${v}` || 'Option is required']"
              ></v-text-field>
            </template>
            <div @click="addOptions(questionIndex)" style="cursor: pointer;" v-if="question.questionType && question.questionType !== 'textbox'">
              <v-icon>{{ iconTypes[question.questionType] }}</v-icon
              ><span class="px-2">Add Option</span>
            </div>
            <div class="py-0 d-flex mt-4">
              <v-switch
                v-model="question.isRequired"
                :disabled="value.disabled"
                style="margin-top: 4px !important;"
              ></v-switch>
              <span class="mt-2">Required</span>
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              outlined
              dense
              clearable
              :items="questionTypes"
              v-model="question.questionType"
              label="Question type"
              @change="changeQuestionType(questionIndex)"
              :rules="[v => !!`${v}` || 'Question type is required']"
            >
              <template v-slot:item="data">
                <span
                  ><v-icon>{{ data.item.icon }}</v-icon> {{ data.item.text }}</span
                >
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn :disabled="value.disabled" color="primary" icon @click="removeQuestion(questionIndex)">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-btn :disabled="value.disabled" color="primary" text @click="addQuestions(index)" class="px-0 pt-4">
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
          Add questions
        </v-btn>
      </v-card-text>
    </v-card>

    <AddGuest v-if="dialog" @close="dialog = false" :index="index" :guestList="guestList" :value="value" />
  </div>
</template>

<script>
import { AddonService, EventSurveyService } from '@services';

import AddGuest from './dialogs/add-guest';

export default {
  components: {
    AddGuest,
  },

  props: {
    value: { type: Object, required: true },
  },

  data() {
    return {
      index: null,
      dialog: false,
      linkTypes: [
        { text: 'Youtube', value: 'youtube' },
        { text: 'Facebook', value: 'facebook' },
        { text: 'Twitter', value: 'twitter' },
      ],
      questionTypes: [
        { text: 'Multiple Choices', value: 'radio', icon: 'mdi-circle-outline' },
        { text: 'Checkboxes', value: 'checkbox', icon: 'mdi-square-outline' },
        { text: 'Dropdown', value: 'select', icon: 'mdi-chevron-down' },
        { text: 'Textbox', value: 'textbox', icon: 'mdi-text' },
      ],
      iconTypes: {
        radio: 'mdi-circle-outline',
        checkbox: 'mdi-square-outline',
        select: 'mdi-chevron-down',
        textbox: 'mdi-text',
      },
      addsons: [],
      addonData: {},
      key: 0,
      promoter: '',
      selectedAddson: {},
      isAdmin: false,
    };
  },
  created() {
    if (this.$user.role === 'admin') {
      this.isAdmin = true;
    }
    if (!this.value.event.guests?.length) {
      this.addGuestList();
    }

    if (!this.value.event.links?.length) {
      this.addLink();
    }
    if (this.$user.role === 'promoter') {
      this.promoter = this.$user.organiser;
    }
    if (this.value.event.organiser) {
      this.promoter = this.value.event.organiser;
    }
    this.getAddson();
  },

  async mounted() {
    await this.prepareAddons();
    this.$bus.$on('onChangePromoter', () => {
      this.promoter = this.value.event.organiser;
      this.getAddson();
    });
    this.value.event.removeAddsons = [];
  },
  methods: {
    quantityRequired(item) {
      let isSuccess = true;
      for (let index = 0; index < item.attributes.length; index++) {
        const element = item.attributes[index];
        if (element.quantity > 0) {
          isSuccess = false;
        }
      }
      return !isSuccess || 'Quantity is required';
    },
    minimunQuantity(item) {
      let isSuccess = true;
      if (item.purchaseQuantity > item.quantity) {
        isSuccess = false;
      }
      return !isSuccess || `Min Quantity Allow ${item.purchaseQuantity}`;
    },
    async prepareAddons() {
      if (this.value.event.addson && this.value.event.addson.length > 0) {
        for (let index = 0; index < this.value.event.addson.length; index++) {
          const element = this.value.event.addson[index];
          let purchaseQuantity = 0;
          for (let aindex = 0; aindex < element.attributes.length; aindex++) {
            const attribute = element.attributes[aindex];
            purchaseQuantity = purchaseQuantity + attribute.purchaseQuantity;
          }
          if (purchaseQuantity > 0) {
            this.value.event.addson[index].isHideDelete = true;
          }
          for (let aindex = 0; aindex < element.addon.attributes.length; aindex++) {
            const attribute = element.addon.attributes[aindex];
            const attributesIndex = element.attributes.findIndex(a => a.id === attribute.id);
            if (attributesIndex > -1) {
              this.value.event.addson[index].attributes[attributesIndex].value = attribute.value;
              this.value.event.addson[index].attributes[attributesIndex].price = attribute.price / 100;
            } else {
              this.value.event.addson[index].attributes.push({
                value: attribute.value,
                price: attribute.price / 100,
                quantity: 0,
                id: attribute.id,
              });
            }
          }
        }
      }
      this.value.event.oldAddson = _.cloneDeep(this.value.event.addson);
    },
    editGuest(index, guestList) {
      this.dialog = true;
      this.index = index;
      this.guestList = guestList;
    },
    async getAddonById(id, index) {
      this.addonData = await AddonService.getById(id);
      if (this.value.event.addson && !this.value.event.addson[index].attributes) {
        this.value.event.addson[index].attributes = [];
      }
      // this.value.event.addson[index].attributes = [];
      for (let aindex = 0; aindex < this.addonData.attributes.length; aindex++) {
        const element = this.addonData.attributes[aindex];
        const addonAttribute = this.value.event.addson[index].attributes.find(a => a.value === element.value);
        if (!addonAttribute) {
          if (aindex === 0) {
            this.value.event.addson[index].attributes = [];
          }
          this.value.event.addson[index].attributes.push({
            value: element.value,
            price: element.price / 100,
            quantity: 0,
            id: element.id,
          });
        }
      }
      this.$forceUpdate();
    },
    async getAddson() {
      const addsonData = await AddonService.get({ limit: 'all', promoter: this.promoter, status: 'active' });
      const addsons = addsonData.addon;
      for (let index = 0; index < addsons.length; index++) {
        const element = addsons[index];
        // let quantity = 0;
        // for (let eindex = 0; eindex < (element.events || []).length; eindex++) {
        //   const ele = element.events[eindex];
        //   quantity = quantity + ele.quantity;
        // }
        // if (quantity < element.quantity) {
        this.addsons.push(element);
        // }
      }
    },
    desimalNumberFormat(value) {
      if (value) {
        value = value.toString();
        value = value.split('.');
        return value.length === 2 && value[1].length > 2 ? false : true;
      }
      return true;
    },
    addGuestList() {
      this.value.event.guests = this.value.event.guests || [];
      this.value.event.guests.push({
        title: `Guest List ${this.value.event.guests.length + 1}`,
        guests: [],
      });
      this.value.change();
      this.$forceUpdate();
    },

    removeGuestList(index, guestList) {
      this.$confirm({
        title: `Are you sure you want to delete ${guestList.title}`,
        skip: !guestList.guests?.length,
        resolve: () => {
          this.value.event.guests?.splice(index, 1);
          this.value.change();
          this.$forceUpdate();
        },
      });
    },

    getFilterAddson(index) {
      return this.addsons.filter(category => {
        return !this.value.event.addson.some((item, i) => {
          let addonId = '';
          if (item.addon && item.addon._id) {
            addonId = item.addon._id;
          } else {
            addonId = item.addon;
          }
          return index != i && item.addon && category._id == addonId;
        });
      });
    },

    removeGuest(index, guestList) {
      guestList?.guests?.splice(index, 1);
      this.value.change();
      this.$forceUpdate();
    },

    getLinkTypes(link) {
      return this.linkTypes.filter(linkType => {
        return (
          link.linkType === linkType.value || !this.value.event.links.some(_link => _link.linkType === linkType.value)
        );
      });
    },

    addLink() {
      this.value.event.links = this.value.event.links || [];

      if (this.value.event.links.length < 3) {
        this.value.event.links.push({});
        this.value.change();
        this.$forceUpdate();
      }
    },

    removeLink(index) {
      this.value.event.links.splice(index, 1);
      this.value.change();
      this.$forceUpdate();
    },

    addAddon() {
      this.value.event.addson = this.value.event.addson || [];
      this.value.event.addson.push({});
      this.value.change();
      this.$forceUpdate();
    },

    removeAddson(index) {
      this.value.event.removeAddsons.push(this.value.event.addson[index].addson);
      this.value.event.addson.splice(index, 1);
      this.value.change();
      this.$forceUpdate();
    },

    async checkAddonQuantity(item, index, attribute, addonIndex) {
      let addonId = '';
      if (item.addon && item.addon._id) {
        addonId = item.addon._id;
      } else {
        addonId = item.addon;
      }
      await this.getAddonById(addonId, addonIndex);
      const addonAttribute = this.addonData.attributes.find(a => a.value === attribute.value);
      let availableQuantity = 0;
      availableQuantity = addonAttribute.quantity;
      const data = await AddonService.getEventAddson(addonId, attribute.id, this.value.event._id);

      if (data && data.length) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          availableQuantity -= element.attributes.quantity;
        }
      }
      let isAvailable = true;
      if (availableQuantity < attribute.quantity) {
        isAvailable = false;
        if (availableQuantity < 0) {
          availableQuantity = 0;
        }
        this.value.event.addson[addonIndex].attributes[index].errors = [];
        this.value.event.addson[addonIndex].attributes[index].errors.push(`Max Quantity Allow ${availableQuantity}`);
      } else {
        this.value.event.addson[addonIndex].attributes[index].errors = [];
      }
      this.value.additionalPageError = !isAvailable;
      this.$forceUpdate();
    },

    addQuestions() {
      this.value.event.survey.questions = this.value.event.survey.questions || [];
      this.value.event.survey.questions.push({});
      this.value.change();
      this.$forceUpdate();
    },

    addOptions(questionIndex) {
      this.value.event.survey.questions[questionIndex].options =
        this.value.event.survey.questions[questionIndex].options || [];
      this.value.event.survey.questions[questionIndex].options.push({ answer: '' });
      this.value.change();
      this.$forceUpdate();
    },

    removeOption(answerIndex, questionIndex) {
      this.value.event.survey.questions[questionIndex].options.splice(answerIndex, 1);
      this.value.change();
      this.$forceUpdate();
    },

    changeQuestionType(questionIndex) {
      this.value.event.survey.questions[questionIndex].options = [];
      if (this.value.event.survey.questions[questionIndex].questionType !== 'textbox') {
        this.value.event.survey.questions[questionIndex].options.push({ answer: '' });
      }
      this.value.change();
      this.$forceUpdate();
    },

    removeQuestion(index) {
      this.value.event.survey.questions.splice(index, 1);
      this.value.change();
      this.$forceUpdate();
    },

    async surveyChange() {
      if (!this.value.event.isHaveSurvey && this.value.event.survey._id) {
        await EventSurveyService.deleteByEvent(this.value.event._id);
      } else if (this.value.event.isHaveSurvey && this.value.event.survey._id) {
        this.value.event.survey.isDeleted = false;
        await EventSurveyService.update(this.value.event.survey);
      }
      this.value.change();
      this.$forceUpdate();
    },
  },
  watch: {
    'value.event.addsons': function(after, before) {
      // Return the object that changed
      var vm = this;
      let changed = after.filter(function(p, idx) {
        return Object.keys(p).some(function(prop) {
          return p[prop] !== vm.$data.oldPeople[idx][prop];
        });
      });
    },
  },
};
</script>
