<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12">
        To replace eticket delivery with box office collection, simply select from the drop down below. Postage is currently unavailable.
      </v-col>

      <v-col cols="12" sm="6">
        <v-select
          label="Method (optional)"
          outlined
          dense
          clearable
          :items="deliveryMethods"
          v-model="value.event.possibleDeliveryMethods[0].method"
          @change="value.change()"
        ></v-select>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
          type="number"
          label="Fee (optional)"
          outlined
          dense
          clearable
          v-model="value.event.possibleDeliveryMethods[0].fee"
          v-on:keypress="isNumber($event)"
          @change="value.change()"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="12">
        <v-textarea
          v-model="value.event.possibleDeliveryMethods[0].description"
          label="Description (optional)"
          outlined
          dense
          clearable
          @change="value.change()"
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object, required: true },
  },

  data() {
    return {
      deliveryMethods: [
        { text: 'Email', value: 'email' },
        { text: 'Postage', value: 'postage' },
        { text: 'Box Office Collection', value: 'boxofficecollection' },
      ],
    };
  },

  created() {
    this.value.event.possibleDeliveryMethods = this.value.event.possibleDeliveryMethods || [{}];
  },
};
</script>
