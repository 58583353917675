<template>
  <div>
    <v-stepper v-model="step" @change="onChangeStep">
      <v-stepper-header>
        <template v-for="step in steps">
          <v-stepper-step
            :key="step.step"
            :edit-icon="step.editIcon"
            :complete="editable(step.step)"
            :editable="editable(step.step)"
            :step="step.step"
          >
            <template>
              {{ step.text }}
            </template>
          </v-stepper-step>

          <v-divider v-if="step.step < steps.length" :key="step.step + 'divider'"></v-divider>
        </template>
      </v-stepper-header>

      <v-divider />

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-form ref="form1" no-validate>
            <event-details
              :value="value"
              @change-genre="getSubCategories()"
              @change-venue="getVenues()"
              @change-event-series="getEventSeries()"
            />
          </v-form>

          <br />

          <v-row>
            <v-col cols="12" sm="3" md="2">
              <v-btn block outlined @click="cancel()">
                Cancel
              </v-btn>
            </v-col>

            <v-spacer />

            <v-col cols="6" sm="3" md="2" v-if="fromAdmin ? true : !value.disabled">
              <v-btn
                v-if="!value.hideSaveAndNext"
                block
                outlined
                color="primary"
                :loading="loading"
                @click="onNextStep(true)"
              >
                Save & next
              </v-btn>
            </v-col>
            <v-col cols="6" sm="3" md="2" v-if="value.event._id">
              <v-btn block color="primary" @click="onNextStep()">
                Next
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-form ref="form2" no-validate :disabled="value.disabled">
            <add-ticket :value="value" />
          </v-form>

          <br />

          <v-row>
            <v-col cols="12" sm="3" md="2">
              <v-btn block outlined @click="onChangeStep(1)">
                Previous
              </v-btn>
            </v-col>

            <v-spacer />

            <v-col cols="6" sm="3" md="2" v-if="fromAdmin ? true : !value.disabled">
              <v-btn
                v-if="!value.hideSaveAndNext"
                block
                outlined
                color="primary"
                :loading="loading"
                @click="onNextStep(true)"
              >
                Save & next
              </v-btn>
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <v-btn block color="primary" @click="onNextStep()">
                Next
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-form ref="form3" no-validate :disabled="value.disabled">
            <additional-details v-if="isLoaded" :value="value" />
          </v-form>

          <br />

          <v-row>
            <v-col cols="12" sm="3" md="2">
              <v-btn block outlined @click="onChangeStep(2)">
                Previous
              </v-btn>
            </v-col>

            <v-spacer />

            <v-col cols="6" sm="3" md="2" v-if="(fromAdmin || !value.disabled) && steps.length == 4">
              <v-btn
                v-if="!value.hideSaveAndNext"
                block
                outlined
                color="primary"
                :loading="loading"
                @click="onNextStep(true)"
              >
                Save & next
              </v-btn>
            </v-col>

            <v-col cols="6" sm="3" md="2">
              <v-btn v-if="steps.length == 4" block color="primary" @click="onNextStep()">
                Next
              </v-btn>

              <v-btn
                v-else-if="(fromAdmin || !value.disabled) && !value.hideSaveAndNext"
                block
                color="primary"
                :loading="loading"
                @click="onNextStep(true)"
              >
                Save
              </v-btn>

              <v-btn v-else-if="value.hideSaveAndNext" block color="primary" :loading="loading" @click="cancelEvent()">
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-form ref="form4" no-validate :disabled="value.disabled">
            <delivery-methods :value="value" />
          </v-form>

          <br />

          <v-row>
            <v-col cols="12" sm="3" md="2">
              <v-btn block outlined @click="onChangeStep(3)">
                Previous
              </v-btn>
            </v-col>

            <v-spacer />

            <v-col cols="6" sm="3" md="2" v-if="(fromAdmin || !value.disabled) && !value.hideSaveAndNext">
              <v-btn block color="primary" :loading="loading" @click="onNextStep(true)">
                Save
              </v-btn>
            </v-col>
            <v-col cols="6" sm="3" md="2" v-if="value.hideSaveAndNext">
              <v-btn block color="primary" :loading="loading" @click="cancelEvent()">
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <SaveDraftDialog v-if="dialog" :value="value" @close="onSaveDraft" />
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
// import { EVENT } from './data';

import EventDetails from './event-details';
import AddTicket from './add-ticket';
import AdditionalDetails from './additional-details';
import DeliveryMethods from './delivery-methods';

import SaveDraftDialog from './dialogs/save-draft';

import { PromoterEventService, PromoterService, SubCategoryService, MediaService, UserService, AddonService, EventSurveyService } from '@services';

export default {
  components: {
    EventDetails,
    AddTicket,
    AdditionalDetails,
    DeliveryMethods,
    SaveDraftDialog,
  },
  props: {
    fromAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      step: 1,
      loading: false,
      dialog: false,
      isLoaded: false,
      value: {
        change() {},
        changed: false,
        additionalPageError: false,
        disabled: true,
        hideSaveAndNext: false,
        stautsDisabled: true,
        validate: false,
        hasEventSeries: false,
        seatingPlanDisabled: false,
        isStatusVetting: false,
        menu: {},
        date: {},
        time: {},
        event: {
          status: 'draft',
          tags: [],
          addsons: [],
          isHaveAddson: false,
          isHaveSurvey: false,
          survey: {}
        },
        venues: [],
        genres: [],
        organisers: [],
        tickets: [],
        eventTypes: [],
        performers: [],
        eventSeries: [],
        seatingPlans: [],
        subCategories: [],
        ticketCategories: [],
        types: ['Reserved Seating', 'Unreserved Seating'],
        unreservedTypes: ['Unreserved Seating'],
        reservedTypes: ['Reserved Seating'],
        statuses: [
          { text: 'Draft', value: 'draft' },
          { text: 'Vetting', value: 'vetting' },
          { text: 'Active', value: 'active' },
          { text: 'Inactive', value: 'inactive' },
          { text: 'Paused', value: 'paused' },
          // { text: 'Private', value: 'private' },
        ],
      },
      steps: [
        { text: 'Event Details', step: 1, editIcon: 'mdi-calendar' },
        { text: 'Add Ticket', step: 2, editIcon: 'mdi-ticket-confirmation-outline' },
        { text: 'Additional Details', step: 3, editIcon: 'mdi-information-outline' },
        { text: 'Delivery Methods', step: 4, editIcon: 'mdi-email-outline' }
      ],
    };
  },
  created() {
    if (this.fromAdmin) {
      this.value.fromAdmin = this.fromAdmin;
      // this.steps.push({ text: 'Delivery Methods', step: 4, editIcon: 'mdi-email-send-outline' });
    }
  },
  async mounted() {
    if (this.$route.params.step) {
      this.step = this.$route.params.step;
    }

    this.value.change = object => {
      if (this.value.setEvent) {
        if (object) {
          object.changed = true;
        }
        this.value.changed = true;
      }

      this.$forceUpdate();
    };

    this.getGenres();
    this.getEvent();
    this.getVenues();
    this.getPerformers();
    this.getEventTypes();
    this.getEventSeries();
    this.getSettingPlans();
    this.getTicketCategories();

    if (this.fromAdmin) {
      this.getOrganisers();
    }
  },
  methods: {
    async getGenres() {
      const response = await PromoterEventService.getGenreSearch({ limit: 'all' });
      if (response) {
        this.value.genres = response.genres;
      }
    },

    async getOrganisers() {
      const promoterdata = await PromoterService.get({ limit: 'all' });
      if (promoterdata) {
        this.value.organisers = promoterdata.organisers;
      }
    },

    async getPerformers() {
      const response = await PromoterEventService.getPerformersSearch({ limit: 'all' });

      if (response) {
        this.value.performers = response.performers;
      }
    },

    async getVenues() {
      const response = await PromoterEventService.getVenuesSearch({ limit: 'all', status: 'active' });

      if (response) {
        this.value.venues = response;
      }
    },

    async getEventSeries() {
      const response = await PromoterEventService.getEventSeries({ limit: 'all' });

      if (response) {
        this.value.eventSeries = response.eventSeries;
      }
    },

    async getEventTypes() {
      const response = await PromoterEventService.getEventTypes({ limit: 'all' });

      if (response) {
        this.value.eventTypes = _.orderBy(response.eventTypes, 'name', 'desc');

        if (!this.value.event.type) {
          this.value.event.type = this.value.eventTypes[0]?._id;
        }
      }
    },

    async getSettingPlans() {
      const response = await PromoterEventService.getSettingPlan({ limit: 'all' });

      if (response) {
        this.value.seatingPlans = response.seatingPlans;
      }
    },

    async getTicketCategories() {
      const response = await PromoterEventService.getTicketCategories({ limit: 'all' });

      if (response) {
        this.value.ticketCategories = response;
      }
    },

    async getSubCategories() {
      if (this.value.event.genre) {
        const response = await SubCategoryService.getByGenreId(this.value.event.genre);

        if (response) {
          this.value.subCategories = response.subCategories;
        }
      }
    },

    setTicket(ticket, sortOrder = 0) {
      ticket.category = ticket.category?._id;
      ticket.bookingFee = Number(ticket.bookingFee / 100);
      ticket.price = Number(ticket.price / 100);
      if (ticket.availableFrom) {
        ticket.availableStartTime = moment(ticket.availableFrom).format('HH:mm');
      }
      if (ticket.availableUntil) {
        ticket.availableEndTime = moment(ticket.availableUntil).format('HH:mm');
      }
      if (ticket.availableFrom) {
        ticket.availableFrom = moment(ticket.availableFrom).format('YYYY-MM-DD');
      }
      if (ticket.availableUntil) {
        ticket.availableUntil = moment(ticket.availableUntil).format('YYYY-MM-DD');
      }

      if (typeof ticket.bucketTickets === 'object') {
        ticket.bucketTickets = 0;
      }
      if(sortOrder !== 0) {
        ticket.sortOrder = sortOrder;
        ticket.changed = true;
      }

      this.value.tickets.push(ticket);
    },

    setTickets() {
      let saveOrderOrder = false;
      if (this.value.event.ticketCategories?.length) {
        this.value.tickets = [];

        this.value.event.ticketCategories.forEach((ticket, index) => {
          if(!ticket.sortOrder) {
            saveOrderOrder = true;
            this.setTicket(ticket, (index + 1));
          } else {
            this.setTicket(ticket);
          }
        });
      }
      if(saveOrderOrder) {
        this.saveTickets();
      }
    },

    mapTicket(_ticket) {
      const excludes = ['changed'];

      const ticket = { ..._ticket };

      delete ticket.ticketCategories;

      ticket.event = this.value.event._id;
      ticket.seatingPlan = this.value.event.seatingPlan;
      ticket.createdBy = this.value.event.organiser;

      ticket.bookingFee = parseInt(ticket.bookingFee * 100);
      ticket.price = parseInt(ticket.price * 100);

      if (!ticket.purchaseLimit) {
        ticket.purchaseLimit = this.value.event.purchaseLimit
      }

      if (ticket.availableFrom && ticket.availableStartTime) {
        ticket.availableFrom = moment(`${ticket.availableFrom} ${ticket.availableStartTime}`).toISOString();
      } else {
        ticket.availableFrom = '';
      }

      if (ticket.availableUntil && ticket.availableEndTime) {
        ticket.availableUntil = moment(`${ticket.availableUntil} ${ticket.availableEndTime}`).toISOString();
      } else {
        ticket.availableUntil = '';
      }

      return _.omit(ticket, excludes);
    },

    setEvent(event, organiserdata) {
      if (event.startsAt) {
        this.value.date.eventStartDate = moment(event.startsAt).format('YYYY-MM-DD');
      }
      if (event.endsAt) {
        this.value.date.eventEndDate = moment(event.endsAt).format('YYYY-MM-DD');
      }
      if (event.availableFrom) {
        this.value.date.availableFrom = moment(event.availableFrom).format('YYYY-MM-DD');
      }
      if (event.availableUntil) {
        this.value.date.availableUntil = moment(event.availableUntil).format('YYYY-MM-DD');
      }
      if (event.startsAt) {
        this.value.time.eventStartTime = moment(event.startsAt).format('HH:mm');
      }
      if (event.endsAt) {
        this.value.time.eventEndTime = moment(event.endsAt).format('HH:mm');
      }
      if (event.availableFrom) {
        this.value.time.availableStartTime = moment(event.availableFrom).format('HH:mm');
      }
      if (event.availableUntil) {
        this.value.time.availableEndTime = moment(event.availableUntil).format('HH:mm');
      }
      if (event.doorsAt) {
        this.value.time.doors = moment(event.doorsAt).format('HH:mm');
      }

      this.value.blurbMain = _.cloneDeep(event.blurbMain);
      this.value.blurbExtra = _.cloneDeep(event.blurbExtra);

      this.value.isStatusVetting = organiserdata ? organiserdata.isStatusVetting : event.organiser?.isStatusVetting;

      event.status = event.status || 'draft';
      event.venue = event.venue?._id;
      event.genre = event.genre?._id;
      event.organiser = event.organiser?._id;
      event.seatingPlan = event.seatingPlan?._id;
      const performers = [];
      event.performers = event.performers ? event.performers : [];
      for (let index = 0; index < event.performers.length; index++) {
        const element = event.performers[index];
        performers.push(element._id);
      }
      event.performers = performers;

      if (event.addson && event.addson.length) {
        for (let index = 0; index < event.addson.length; index++) {
          const element = event.addson[index];
          for (let aindex = 0; aindex < element.attributes.length; aindex++) {
            const attribute = element.attributes[aindex];
            event.addson[index].attributes[aindex].price = attribute.price/100;
          }
        }
      }

      if(!event.survey.questions) {
        event.survey.questions = []
      }
    },

    mapEvent() {
      const excludes = ['ticketCategories', 'promocodes'];

      const event = { ...this.value.event };
      const { date, time } = this.value;

      if (date.eventStartDate && time.eventStartTime) {
        event.startsAt = moment(`${date.eventStartDate} ${time.eventStartTime}`).toISOString();
      } else {
        event.startsAt = '';
      }

      if (date.eventEndDate && time.eventEndTime) {
        event.endsAt = moment(`${date.eventEndDate} ${time.eventEndTime}`).toISOString();
      } else {
        event.endsAt = '';
      }

      if (date.eventStartDate && time.doors) {
        event.doorsAt = moment(`${date.eventStartDate} ${time.doors}`).toISOString();
      } else {
        event.doorsAt = '';
      }

      if (date.availableFrom && time.availableStartTime) {
        event.availableFrom = moment(`${date.availableFrom} ${time.availableStartTime}`).toISOString();
      } else {
        event.availableFrom = '';
      }

      if (date.availableUntil && time.availableEndTime) {
        event.availableUntil = moment(`${date.availableUntil} ${time.availableEndTime}`).toISOString();
      } else {
        event.availableUntil = '';
      }

      event.organiser = event.organiser?._id || event.organiser;

      event.links = (event.links || []).filter(link => !!link.url);

      if (!this.value.event.image && this.value.oldImage) {
        event.image = this.value.oldImage;
      }

      if (event.addson && event.addson.length) {
        for (let index = 0; index < event.addson.length; index++) {
          const element = event.addson[index];
          for (let aindex = 0; aindex < element.attributes.length; aindex++) {
            const attribute = element.attributes[aindex];
            event.addson[index].attributes[aindex].price = attribute.price * 100;
          }
        }
      }

      return _.omit(event, excludes);
    },

    async getEvent() {
      if (this.$route.params.id) {
        const response = await PromoterEventService.getById(this.$route.params.id);
        let organiserdata;
        if (this.$user.organiser) {
          organiserdata = await UserService.getOrganiserInfo(this.$user.organiser);
        }
        if (response) {
          response.ticketCategories.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return a.sortOrder - b.sortOrder;
          });
          this.value.event = response;
          const eventSurvey = await EventSurveyService.getByEvent(response._id);
          this.value.event.survey = eventSurvey || {}

          this.isLoaded = true;
          this.setEvent(this.value.event, organiserdata);
          if (this.value.event.status === 'cancelled') {
            this.value.statuses.push({ text: 'Cancelled', value: 'cancelled' });
          }
          this.setTickets();

          this.getSubCategories();
        } else {
          this.$router.go(-1);
        }
      }

      this.value.hasEventSeries = !!this.value.event.series;
      this.setDisabled();

      this.value.setEvent = true;
      this.$scrollToTop();
    },

    setDisabled() {
      if (this.$route.params.id) {
        // if (['draft', 'vetting', 'paused'].includes(this.value.event.status)) {
        //   this.value.disabled = false;
        //   this.value.stautsDisabled = false;
        // } else {
        if (this.fromAdmin) {
          this.value.disabled = false;
          this.value.stautsDisabled = false;
        } else {
          this.value.disabled = false;
          this.value.stautsDisabled = false;
        }
        // }
      } else {
        this.value.disabled = false;
        this.value.stautsDisabled = true;
      }
      if (this.value.event.status === 'cancelled') {
        this.value.disabled = true;
        this.value.stautsDisabled = true;
        this.value.hideSaveAndNext = true;
      }
    },

    onNextStep(save) {
      const nextStep = this.step + 1;

      const form = this.$refs[`form${this.step}`];
      this.value.validate = true;

      if (form.validate() && this.value.event.blurbMain && !this.value.additionalPageError) {
        if (this.step == this.steps.length && this.value.event.status == 'draft') {
          this.dialog = true;
        } else {
          if (save && this.value.changed) {
            this.$confirm({
              title: `Are you sure you want to update this event?`,
              skip: !this.$route.params.id,
              resolve: () => {
                if (this.value.changed) {
                  this.save(nextStep);
                }
                this.value.changed = false;
              },
            });
          } else {
            this.onChangeStep(nextStep);
          }
        }
      }
    },

    onChangeStep(step) {
      if (step && step <= this.steps.length) {
        if (this.$route.query.isDuplicate && this.value.event._id && this.value.event.slug) {
          this.$router
            .replace({
              name: this.fromAdmin ? 'admin.events.edit' : 'promoter.events.edit',
              params: {
                id: this.value.event.slug,
                step,
              },
              query: { isDuplicate: 'true' },
            })
            .catch(() => {});
        } else if (this.value.event._id && this.value.event.slug) {
          this.$router
            .replace({
              name: this.fromAdmin ? 'admin.events.edit' : 'promoter.events.edit',
              params: {
                id: this.value.event.slug,
                step,
              },
            })
            .catch(() => {});
        }

        this.step = step;
        this.$scrollToTop();
      } else {
        this.$router.go(-1);
      }
    },

    async upoadImage() {
      if (this.value.file) {
        this.value.event.image = `${this.value.event.slug}-${new Date().getTime()}`;

        const response = await MediaService.uploadimage(`${this.value.event._id}-${this.value.event.image}`, {
          file: this.value.file,
        });

        if (response) {
          this.value.file = null;
          return 'success';
        } else {
          this.$root.$emit('image-error');
          return null;
        }
      }
      return 'success';
    },

    async removeImage() {
      const response = await MediaService.removeimage(`${this.value.event._id}-${this.value.oldImage}`);
      if (response) {
        return 'success';
      }
      return null;
    },

    async saveTickets() {
      if (this.value.event._id) {
        const tickets = [];
        this.value.tickets.forEach(async ticket => {
          if (ticket.changed) {
            tickets.push(this.mapTicket(ticket));
          }
        });

        if (tickets.length) {
          const response = await PromoterEventService.saveTickets(this.value.event._id, tickets);
          if (response) {
            //
            const eventData = await PromoterEventService.getById(this.value.event.slug);
            if (eventData) {
              for (let index = 0; index < eventData.ticketCategories.length; index++) {
                const element = eventData.ticketCategories[index];
                eventData.ticketCategories[index].bookingFee = Number(element.bookingFee / 100);
                eventData.ticketCategories[index].price = Number(element.price / 100);
                if (typeof eventData.ticketCategories[index].bucketTickets === 'object') {
                  eventData.ticketCategories[index].bucketTickets = 0;
                }
                if (eventData.ticketCategories[index].availableFrom) {
                  eventData.ticketCategories[index].availableStartTime = moment(eventData.ticketCategories[index].availableFrom).format('HH:mm');
                }
                if (eventData.ticketCategories[index].availableUntil) {
                  eventData.ticketCategories[index].availableEndTime = moment(eventData.ticketCategories[index].availableUntil).format('HH:mm');
                }
                if (eventData.ticketCategories[index].availableFrom) {
                  eventData.ticketCategories[index].availableFrom = moment(eventData.ticketCategories[index].availableFrom).format('YYYY-MM-DD');
                }
                if (eventData.ticketCategories[index].availableUntil) {
                  eventData.ticketCategories[index].availableUntil = moment(eventData.ticketCategories[index].availableUntil).format('YYYY-MM-DD');
                }
              }
              this.value.tickets = eventData.ticketCategories;
            }
          }
        }
      }
    },

    async save(step) {
      this.loading = true;
      let imageResponse;

      const response = await PromoterEventService.save(this.mapEvent());
      if(this.value.event && this.value.event.survey && this.value.event.survey._id) {
        await EventSurveyService.update(this.value.event.survey)
      } else if(this.value.event.isHaveSurvey){
        this.value.event.survey.event = this.value.event._id;
        await EventSurveyService.save(this.value.event.survey)
      }
      if(this.value.event._id) {
        const eventSurvey = await EventSurveyService.getByEvent(this.value.event._id);
        this.value.event.survey = eventSurvey || {}
        if (this.value.event.addson && this.value.event.addson.length) {
          for (let index = 0; index < this.value.event.addson.length; index++) {
            const element = this.value.event.addson[index];
            for (let aindex = 0; aindex < element.attributes.length; aindex++) {
              const attribute = element.attributes[aindex];
              this.value.event.addson[index].attributes[aindex].price = attribute.price/100;
            }
          }
        }
      }

      // TODO: response should not be  '' in update api
      if (response || response === '') {
        if (response._id) {
          this.value.event._id = response._id;
        }
        this.isLoaded = true;
        if (!this.value.event.image && this.value.oldImage) {
          imageResponse = await this.removeImage();
        } else {
          imageResponse = await this.upoadImage();
        }
        this.$success('Event update successfully');
        this.saveTickets();
   
        this.onChangeStep(step);
        this.setDisabled();
      }

      this.loading = false;
    },

    cancel() {
      this.$confirm({
        title: `Are you sure you want to cancel?`,
        skip: !this.value.changed,
        resolve: () => {
          this.$router.go(-1);
        },
      });
    },

    editable(step) {
      return this.step > step || !!this.$route.params.id;
    },

    onSaveDraft(status) {
      this.dialog = false;

      if (status) {
        this.value.event.status = status;
        this.save(this.steps.length + 1);
      }
    },

    cancelEvent() {
      this.$next({ name: this.fromAdmin ? 'admin.events' : 'promoter.events' });
    },
  },
};
</script>
