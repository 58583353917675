<template>
  <v-dialog v-model="dialog" width="800px" @click:outside="cancel()">
    <v-card>
      <v-card-title class="primary--text">
        Create Venue
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                outlined
                dense
                name="name"
                label="Name"
                v-model="data.name"
                @input="errors = []"
                :error-messages="errors"
                @change="onChangeName()"
                placeholder="e.g. 2 Northdown"
                :rules="[v => !!v || 'Name is required']"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="primary--text text-bold">
              Address
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" class="py-0">
              <postcode @change="onChangePostcode" />
            </v-col>

            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                outlined
                dense
                name="address"
                label="Venue Place"
                v-model="data.place"
                :rules="[v => !!v || 'Venue Place is required']"
                placeholder="e.g. London"
              />
            </v-col>

            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                outlined
                dense
                name="addressline1"
                label="Address line 1"
                v-model="data.address.line1"
                :rules="[v => !!v || 'Address line 1 is required']"
                placeholder="e.g. Active"
              />
            </v-col>

            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                outlined
                dense
                name="addressline2"
                label="Address line 2"
                v-model="data.address.line2"
                placeholder="e.g. 530 WN"
              />
            </v-col>

            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                outlined
                dense
                name="city"
                label="Town/City"
                v-model="data.address.city"
                :rules="[v => !!v || 'Town/City Place is required']"
                placeholder="e.g. London"
              />
            </v-col>

            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                outlined
                dense
                name="county"
                label="County"
                v-model="data.address.county"
                :rules="[v => !!v || 'County is required']"
                placeholder="e.g. Active"
              />
            </v-col>

            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                outlined
                dense
                name="zip"
                label="Postal Code"
                v-model="data.address.postcode"
                :rules="[v => !!v || 'Postal Code is required']"
                placeholder="e.g. 530 WN"
              />
            </v-col>

            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                outlined
                dense
                name="country"
                label="Country"
                v-model="data.address.country"
                :rules="[v => !!v || 'Country is required']"
                placeholder="e.g. London"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined dense color="primary" @click="cancel()">Cancel</v-btn>
        <v-btn width="100" color="primary" :loading="loading" @click="saveVenue()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { PromoterEventService, VenueService } from '@services';

export default {
  data() {
    return {
      dialog: true,
      loading: false,
      data: {
        address: {},
      },
      errors: [],
    };
  },
  methods: {
    async onChangePostcode(address) {
      this.data.address = address;
    },

    async saveVenue() {
      this.loading = true;

      if (this.$refs.form.validate() && !this.errors.length) {
        this.data.organiser = this.$user.organiser;
        if (this.$user.role === 'admin') {
          this.data.status = 'active';
        }
        const response = await PromoterEventService.addVenue(this.data);
        if (response) {
          this.dialog = false;
          this.$emit('close', response);
        }
      }

      this.loading = false;
    },

    cancel() {
      this.dialog = false;
      this.$emit('close');
    },

    async onChangeName() {
      this.errors = [];
      if (this.data.name) {
        const response = await VenueService.checkDuplicate(this.data.name);
        if (response) {
          this.errors = ['Venue name already exists'];
        }
      }
      this.$forceUpdate();
    },
  },
};
</script>
