var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"500px"},on:{"click:outside":function($event){return _vm.cancel()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",attrs:{"no-validate":""}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_c('b',[_vm._v(_vm._s(_vm.edit ? 'Edit' : 'Add')+" guest")])]),_c('v-divider'),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Guest name and last name","rules":[v => !!v && v && !!v.trim() || 'Guest name and last name is required'],"outlined":"","dense":"","name":"name"},model:{value:(_vm.guestInput.name),callback:function ($$v) {_vm.$set(_vm.guestInput, "name", $$v)},expression:"guestInput.name"}}),_c('v-text-field',{attrs:{"label":"Email","rules":[
            v => !v || /.+@.+\..+/.test(v) || 'Email must be valid',
            v => _vm.validateUnique(v, 'email') || 'Email already exists',
          ],"outlined":"","dense":"","name":"email"},model:{value:(_vm.guestInput.email),callback:function ($$v) {_vm.$set(_vm.guestInput, "email", $$v)},expression:"guestInput.email"}}),_c('v-text-field',{attrs:{"label":"Contact number","rules":[
            v => !v || /^\d{11}$/.test(v) || 'Contact number must be valid',
            v => _vm.validateUnique(v, 'number') || 'Contact number already exists',
          ],"outlined":"","dense":"","name":"number"},model:{value:(_vm.guestInput.number),callback:function ($$v) {_vm.$set(_vm.guestInput, "number", $$v)},expression:"guestInput.number"}}),_c('v-text-field',{attrs:{"label":"Company Name","outlined":"","dense":""},model:{value:(_vm.guestInput.companyName),callback:function ($$v) {_vm.$set(_vm.guestInput, "companyName", $$v)},expression:"guestInput.companyName"}}),_c('v-text-field',{attrs:{"label":"#Seats","outlined":"","dense":"","maxlength":2,"name":"number","rules":[
            v => !v || /^\d+$/.test(v) || '#Seats must be valid',
          ]},model:{value:(_vm.guestInput.seats),callback:function ($$v) {_vm.$set(_vm.guestInput, "seats", $$v)},expression:"guestInput.seats"}}),_c('v-textarea',{attrs:{"label":"Additional Comments","rows":"3","rules":[
            v => !v || v.length <= 150 || 'Max 150 characters',
          ]},model:{value:(_vm.guestInput.comments),callback:function ($$v) {_vm.$set(_vm.guestInput, "comments", $$v)},expression:"guestInput.comments"}})],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"hidden-xs-only",attrs:{"cols":"6","sm":"3"}},[_c('v-btn',{attrs:{"block":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel ")])],1),_c('v-spacer'),(!_vm.edit)?_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-btn',{attrs:{"outlined":"","color":"primary","block":""},on:{"click":function($event){return _vm.save(true)}}},[_vm._v(" Add more ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }