<template>
  <v-dialog v-model="dialog" width="500px" @click:outside="cancel()">
    <v-form ref="form" no-validate>
      <v-card>
        <v-card-title class="justify-center">
          <b>{{ edit ? 'Edit' : 'Add' }} guest</b>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-text-field
            label="Guest name and last name"
            v-model="guestInput.name"
            :rules="[v => !!v && v && !!v.trim() || 'Guest name and last name is required']"
            outlined
            dense
            name="name"
          ></v-text-field>

          <v-text-field
            label="Email"
            v-model="guestInput.email"
            :rules="[
              v => !v || /.+@.+\..+/.test(v) || 'Email must be valid',
              v => validateUnique(v, 'email') || 'Email already exists',
            ]"
            outlined
            dense
            name="email"
          ></v-text-field>

          <v-text-field
            label="Contact number"
            v-model="guestInput.number"
            :rules="[
              v => !v || /^\d{11}$/.test(v) || 'Contact number must be valid',
              v => validateUnique(v, 'number') || 'Contact number already exists',
            ]"
            outlined
            dense
            name="number"
          ></v-text-field>

          <v-text-field
            label="Company Name"
            v-model="guestInput.companyName"
            outlined
            dense
          ></v-text-field>

          <v-text-field
            label="#Seats"
            v-model="guestInput.seats"
            outlined
            dense
            :maxlength="2"
            name="number"
            :rules="[
              v => !v || /^\d+$/.test(v) || '#Seats must be valid',
            ]"
          ></v-text-field>

          <v-textarea
            label="Additional Comments"
            rows="3"
            v-model="guestInput.comments"
            :rules="[
              v => !v || v.length <= 150 || 'Max 150 characters',
            ]"
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="6" sm="3" class="hidden-xs-only">
              <v-btn block @click="cancel()"> Cancel </v-btn>
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="6" sm="3" v-if="!edit">
              <v-btn outlined color="primary" block @click="save(true)"> Add more </v-btn>
            </v-col>
            <v-col cols="6" sm="3">
              <v-btn color="primary" block @click="save()"> Save </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    value: { type: Object, required: true },
    index: { type: Number, required: false },
    guestList: { type: Object, required: false },
  },

  data() {
    return {
      dialog: true,
      edit: this.index || this.index === 0,
      guestInput: {
        name: '',
        number: '',
        email: '',
        companyName: '',
        seats: '',
        comments: '',
      },
    };
  },

  mounted() {
    if (this.index || this.index === 0) {
      this.guestInput = _.cloneDeep(this.guestList.guests[this.index]);
    }
  },

  methods: {
    save(more) {
      if (this.$refs.form.validate()) {
        if (this.index || this.index === 0) {
          this.guestList.guests[this.index] = this.guestInput;
        } else {
          this.guestList.guests = this.guestList.guests || [];
          this.guestList.guests.push(_.cloneDeep(this.guestInput));
        }

        if (more) {
          this.$refs.form.reset();
        } else {
          this.dialog = false;
          this.$emit('close');
        }

        this.value.change();
      }
    },
    cancel() {
      this.dialog = false;
      this.$emit('close');
    },
    validateUnique(value, key) {
      return !this.guestList.guests.some((guest, index) => {
        return index !== this.index && guest[key] && value && guest[key].toLowerCase() === value.toLowerCase();
      });
    },
  },
};
</script>
